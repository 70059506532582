.toggle-button {
  outline: none !important;
  margin-top: 2px !important;
  margin-right: -20px !important;
}


.sc-bdVaJa.bLyUej {
  outline-color: transparent !important;
  border-color: transparent !important;
}

span.sc-htpNat.sc-gqjmRU.fygmjP,
span.sc-htpNat.sc-gqjmRU.fygmjP::before,
span.sc-htpNat.sc-gqjmRU.fygmjP::after,
span.sc-htpNat.sc-gqjmRU.fygmjP {
  color: rgba(19, 19, 19, 0.6);
  width: 28px !important;
  height: 2px;
}

span.sc-htpNat.sc-gqjmRU.hJasVL,
span.sc-htpNat.sc-gqjmRU.hJasVL::before,
span.sc-htpNat.sc-gqjmRU.hJasVL::after {
  color: rgba(19, 19, 19, 0.6);
  background-color: rgba(19, 19, 19, 0.6);
  width: 28px !important;
  height: 2px;
}
span.sc-dlfnbm.sc-iqHYGH.fPSnJB.ekpwJh,
span.sc-dlfnbm.sc-iqHYGH.fPSnJB.ekpwJh::before,
span.sc-dlfnbm.sc-iqHYGH.fPSnJB.ekpwJh::after {
  background-color: #fff;
}
span.sc-dlfnbm.sc-iqHYGH.fPSnJB.dkGITB,
span.sc-dlfnbm.sc-iqHYGH.fPSnJB.dkGITB::before,
span.sc-dlfnbm.sc-iqHYGH.fPSnJB.dkGITB::after {
  background-color: #fff;
}