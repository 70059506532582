@import url(https://fonts.googleapis.com/css?family=Lato:300,400,500,700);
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto Slab", "Roboto Slab", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Roboto Slab",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FAFBFC;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.toggle-button {
  outline: none !important;
  margin-top: 2px !important;
  margin-right: -20px !important;
}


.sc-bdVaJa.bLyUej {
  outline-color: transparent !important;
  border-color: transparent !important;
}

span.sc-htpNat.sc-gqjmRU.fygmjP,
span.sc-htpNat.sc-gqjmRU.fygmjP::before,
span.sc-htpNat.sc-gqjmRU.fygmjP::after,
span.sc-htpNat.sc-gqjmRU.fygmjP {
  color: rgba(19, 19, 19, 0.6);
  width: 28px !important;
  height: 2px;
}

span.sc-htpNat.sc-gqjmRU.hJasVL,
span.sc-htpNat.sc-gqjmRU.hJasVL::before,
span.sc-htpNat.sc-gqjmRU.hJasVL::after {
  color: rgba(19, 19, 19, 0.6);
  background-color: rgba(19, 19, 19, 0.6);
  width: 28px !important;
  height: 2px;
}
span.sc-dlfnbm.sc-iqHYGH.fPSnJB.ekpwJh,
span.sc-dlfnbm.sc-iqHYGH.fPSnJB.ekpwJh::before,
span.sc-dlfnbm.sc-iqHYGH.fPSnJB.ekpwJh::after {
  background-color: #fff;
}
span.sc-dlfnbm.sc-iqHYGH.fPSnJB.dkGITB,
span.sc-dlfnbm.sc-iqHYGH.fPSnJB.dkGITB::before,
span.sc-dlfnbm.sc-iqHYGH.fPSnJB.dkGITB::after {
  background-color: #fff;
}
.toolbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0,0.6);
    height: 50px;
    padding-top: 0rem;
    padding: 0 10px;
    z-index: 1000;
    border-bottom: 4px solid hsl(0, 4%, 86%);
}

.toolbar-wrapper {
    height: 100%;
    background: rgba(0, 0, 0, 0.5) ;
    width: 100%;
    margin: 0 auto;
}
  
.toolbar__navigation {
    display: -webkit-flex;
    display: flex;
    height: 100%;
    width: 100%;
    -webkit-align-items: center;
            align-items: center;
}
.toolbar_navigation-header h1{
    color: #fff;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin: 0;
    padding-top: 20px;
} 
.toolbar__logo a img {
    text-decoration: none;
    width: 60px;
    height: 60px;
}
  
.spacer {
    -webkit-flex: 1 1;
            flex: 1 1;
}
  
.toolbar_navigation-items ul {
    list-style: none;
    margin: 0;
    padding-top: 20px;
    padding-bottom: 15px;
    display: -webkit-flex;
    display: flex;
}
  
.toolbar__logo {
    margin-top: 0px;
    margin-left: 0rem;
}
  
.toolbar_navigation-items li {
    padding-bottom: 5px;
}
.toolbar_navigation-items a {
    color: rgb(255, 255, 255);
    text-decoration: none;
    padding-top: 2rem;
    padding-bottom: 1rem;
    transition: .4s;
}
  
.toolbar_navigation-items a:hover,
.toolbar_navigation-items a:active {
    color: rgb(255, 255, 255);
    box-shadow: 1px 0px 1px rgba(121, 120, 120, 0.5);
}

@media (max-width: 1200px) {
    .toolbar-wrapper {
        padding: 0 15px;
        padding-bottom: 20px;
    }
}
  
@media (max-width: 775px) {
    .toolbar {
        padding: 0px;
        height: 76px;
    }
    .toolbar_navigation-items {
        display: none;
    }
    .toolbar__logo a img {
        width: 60px;
        height: 60px;
    }
    .toolbar__logo {
        margin-bottom: 4px;
        margin-top: 9px;
    }
}
  
@media (min-width: 769px) {
    .toolbar__toggle-button {
        display: none;
    }
    
    .toolbar__logo {
        margin-left: 0;
    }
}
  
  
.side-drawer {
    height: 100%;
    background: rgba(19, 19, 19, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    width: 60%;
    max-width: 400px;
    z-index: 10000;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    transition: -webkit-transform 0.4s ease-out;
    transition: transform 0.4s ease-out;
    transition: transform 0.4s ease-out, -webkit-transform 0.4s ease-out;
}

.side-drawer.open {
    -webkit-transform: translateX(0);
            transform: translateX(0);
}

.side-drawer ul li a {
    color: #ffffff;
    text-decoration: none;
    font-size: 1.15rem;
    display: block;
    padding: 20px 0;
}

.side-drawer .btn {
    display: block;
    padding: 35px 0;
}

.side-drawer ul {
    margin: 0;
    padding: 0;
    height: 100%;
    list-style: none;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-content: flex-start;
            align-content: flex-start;
}

.side-drawer ul li {
    box-sizing: border-box;
    text-align: center;
    border-bottom: 2px solid #f1f1f1;
}

.side-drawer li:last-child {
    border-bottom: none;
}



@media (min-width: 769px) {
    .side-drawer {
        display: none;
    }
}


/* Ripple effect */
.ripple {
    background-position: center;
    transition: background 0.8s;
}

.ripple:hover {
    background: rgba(12, 12, 12, 0.9) radial-gradient(circle, transparent 40%, rgba(19, 19, 19, 0.7) 1%) center/15000%;
}

.ripple:active {
    background-color: rgba(19, 18, 18, 0.8);
    background-size: 100%;
    transition: background 0s;
}
.backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 9000;
  }

