.side-drawer {
    height: 100%;
    background: rgba(19, 19, 19, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    width: 60%;
    max-width: 400px;
    z-index: 10000;
    transform: translateX(-100%);
    transition: transform 0.4s ease-out;
}

.side-drawer.open {
    transform: translateX(0);
}

.side-drawer ul li a {
    color: #ffffff;
    text-decoration: none;
    font-size: 1.15rem;
    display: block;
    padding: 20px 0;
}

.side-drawer .btn {
    display: block;
    padding: 35px 0;
}

.side-drawer ul {
    margin: 0;
    padding: 0;
    height: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
}

.side-drawer ul li {
    box-sizing: border-box;
    text-align: center;
    border-bottom: 2px solid #f1f1f1;
}

.side-drawer li:last-child {
    border-bottom: none;
}



@media (min-width: 769px) {
    .side-drawer {
        display: none;
    }
}


/* Ripple effect */
.ripple {
    background-position: center;
    transition: background 0.8s;
}

.ripple:hover {
    background: rgba(12, 12, 12, 0.9) radial-gradient(circle, transparent 40%, rgba(19, 19, 19, 0.7) 1%) center/15000%;
}

.ripple:active {
    background-color: rgba(19, 18, 18, 0.8);
    background-size: 100%;
    transition: background 0s;
}