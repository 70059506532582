.toolbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0,0.6);
    height: 50px;
    padding-top: 0rem;
    padding: 0 10px;
    z-index: 1000;
    border-bottom: 4px solid hsl(0, 4%, 86%);
}

.toolbar-wrapper {
    height: 100%;
    background: rgba(0, 0, 0, 0.5) ;
    width: 100%;
    margin: 0 auto;
}
  
.toolbar__navigation {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
}
.toolbar_navigation-header h1{
    color: #fff;
    display: flex;
    align-items: center;
    margin: 0;
    padding-top: 20px;
} 
.toolbar__logo a img {
    text-decoration: none;
    width: 60px;
    height: 60px;
}
  
.spacer {
    flex: 1;
}
  
.toolbar_navigation-items ul {
    list-style: none;
    margin: 0;
    padding-top: 20px;
    padding-bottom: 15px;
    display: flex;
}
  
.toolbar__logo {
    margin-top: 0px;
    margin-left: 0rem;
}
  
.toolbar_navigation-items li {
    padding-bottom: 5px;
}
.toolbar_navigation-items a {
    color: rgb(255, 255, 255);
    text-decoration: none;
    padding-top: 2rem;
    padding-bottom: 1rem;
    transition: .4s;
}
  
.toolbar_navigation-items a:hover,
.toolbar_navigation-items a:active {
    color: rgb(255, 255, 255);
    box-shadow: 1px 0px 1px rgba(121, 120, 120, 0.5);
}

@media (max-width: 1200px) {
    .toolbar-wrapper {
        padding: 0 15px;
        padding-bottom: 20px;
    }
}
  
@media (max-width: 775px) {
    .toolbar {
        padding: 0px;
        height: 76px;
    }
    .toolbar_navigation-items {
        display: none;
    }
    .toolbar__logo a img {
        width: 60px;
        height: 60px;
    }
    .toolbar__logo {
        margin-bottom: 4px;
        margin-top: 9px;
    }
}
  
@media (min-width: 769px) {
    .toolbar__toggle-button {
        display: none;
    }
    
    .toolbar__logo {
        margin-left: 0;
    }
}
  
  